.careerVisualizationElement {
    position: absolute;

    left: 5vw;
    top: 36vw;
    width: 90vw;
    height: 90vw;
    border: 0.25vw solid #efefef;
    background-color: black;

    z-index: 10;
}

.careerDurationText {
    position: relative;

    color: #efefef;
    font-family: "CierraText";
    font-size: 2vw;
    font-weight: 900;
    text-align: left;
    line-height: 3.5vw;

    margin-left: 4vw;
    margin-right: 4vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.careerVisTitle {
    position: relative;
    color: #efefef;
    width: fit-content;
    font-family: "CierraText";
    font-size: 3vw;
    font-weight: 900;
    text-align: left;
    margin-left: 4vw;
    margin-right: 10vw;
    margin-top: 4vw;
}

.careerVisRowText {
    margin-left: 1vw;
    margin-right: 1vw;

    font-size: 3vw;

    border: solid #efefef 0.25vw;
    color: black;
    background-color: #efefef;
    border-radius: 2vw;
    padding: 1vw;
}

.careerVizHeader {
    display: flex;
    width: 90vw;
    border-bottom: 0.25vw solid #efefef;
}

.careerVisRow {
    position: absolute;
    display: flex;
    right: 1vw;
    margin-top: -1.75vw;
}

.careerTagsRow {
    position: absolute;
    display: flex;
    bottom: 2vw;
    right: 2vw;
    margin-top: -1.75vw;
}

.careerTagBox {
    width: 3vw;
    height: 6vw;

    margin-left: 1vw;
}

.careerTagBoxeng {
    background-color: #C32376;
}

.careerTagBoxentrep {
    background-color: #df46b7;
}

.careerTagBoxdesign {
    background-color: #ffc6d3;
}